import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import Layout from "../../components/Layout"
import Hoverimage from "../../components/Hoverimage"
import { getImage } from "gatsby-plugin-image"
import PhotoMasonry from "../../components/Masonry"
import Heading from "../../components/Heading"

const pageQuery = graphql`
query Analogues {
  allGraphCmsAnalogue(sort: {fields: order_id, order: DESC}) {
    nodes {
      heading
      order_id
      remoteId
      image {
        localFile {
          childImageSharp {
            gatsbyImageData(width: 500, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
      }
    }
  }
}

`
export default function Analogues() {
  const {
    allGraphCmsAnalogue: { nodes: projects },
  } = useStaticQuery(pageQuery)
  return (
    <Layout title="Katharina Kohler | Analog" description="Katharina Kohler - M.Sc. psychologist and artist, analog art, Berlin, Germany">
      <Heading>Analog</Heading>
      <div className="flex justify-center">
        <PhotoMasonry>
          {projects.map(({ heading, remoteId, ...project }) => (
              <Hoverimage
                key={remoteId}
                title={heading}
                image={getImage(project.image[0].localFile)}
                to={"/analog/" + remoteId}
                className="mb-4"
              />
          ))}
        </PhotoMasonry>
      </div>
    </Layout>
  )
}
